.row-DEBUG td {
  color: #a1a1a1;
}
.row-TRACE td {
  color: #c9c9c9;
}
.row-WARN td {
  color: #ffbd4b;
}
.row-ERROR td {
  color: #ff4b4b;
}
